import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DeliveryPage = () => (
  <Layout>
    <SEO
      title="Punti di consegna"
      keywords={[`gatsby`, `application`, `react`]}
    />
  </Layout>
)

export default DeliveryPage
